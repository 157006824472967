import React, { useState } from 'react';

import Notification from 'src/notifications/Notification';
import { NotificationContext } from 'src/notifications/context';
import type { NotificationType } from 'src/notifications/types';

interface Props {
    children: React.ReactNode;
}

export const NotificationsProvider: React.FC<Props> = ({ children }) => {
    const [notifications, setNotifications] = useState<Map<string, NotificationType>>(new Map());

    const addNotification = (
        message: NotificationType['message'],
        variant: NotificationType['variant']
    ) => {
        setNotifications((state) => {
            const id = String(Math.random());
            const newState = new Map(state);
            newState.set(id, { message, variant });
            return newState;
        });
    };

    return (
        <NotificationContext.Provider value={{
            addNotification
        }}
        >
            {children}
            {Array.from(notifications)?.map(([id, notification]) => (
                <Notification key={id} severity={notification.variant}>
                    {notification.message}
                </Notification>
            ))}
        </NotificationContext.Provider>
    );
};

export default NotificationsProvider;